import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';

import { OperationTypeEnum } from '@data-import/data-access/bulk-operations-api';
import { FeatureFlagService } from '@data-import/feature-flag';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class DisabledOperationService {
  private _disabledOperations = new BehaviorSubject<{ [key: string]: boolean }>({});

  constructor(private featureFlagService: FeatureFlagService) {
    this.getFeatureToggles();
  }

  getDisabledOperations(): Observable<{ [key: string]: boolean }> {
    return this._disabledOperations.asObservable();
  }

  private getFeatureToggles(): void {
    combineLatest([
      this.featureFlagService.getFlagValue('disable-salary-update-service'),
      this.featureFlagService.getFlagValue('disable-ending-of-fixed-transactions'),
      this.featureFlagService.getFlagValue('disable-time-agreements-update'),
      this.featureFlagService.getFlagValue('disable-vacation-balances'),
      this.featureFlagService.getFlagValue('disable-bulk-edit-tags'),
      this.featureFlagService.getFlagValue('disable-absence-import'),
      this.featureFlagService.getFlagValue('disable-bulk-end-positions'),
      this.featureFlagService.getFlagValue('disable-bulk-create-user'),
      this.featureFlagService.getFlagValue('disable-bulk-edit-manager'),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          salaryUpdateServiceToggle,
          endFixedTransactionServiceToggle,
          timeAgreementsUpdateToggle,
          vacationBalanceImportToggle,
          bulkTagsEditToggle,
          absenceImportToggle,
          endPositionsToggle,
          bulkCreateUserToggle,
          bulkEditManagerToggle,
        ]) => {
          this._disabledOperations.next({
            [OperationTypeEnum.SalaryUpdateV2]: salaryUpdateServiceToggle,
            [OperationTypeEnum.EndFixedTransactions]: endFixedTransactionServiceToggle,
            [OperationTypeEnum.ChangeTimeAgreements]: timeAgreementsUpdateToggle,
            [OperationTypeEnum.VacationBalanceImport]: vacationBalanceImportToggle,
            [OperationTypeEnum.PositionTagsUpdate]: bulkTagsEditToggle,
            [OperationTypeEnum.AbsenceRegistrationImport]: absenceImportToggle,
            [OperationTypeEnum.EndPositions]: endPositionsToggle,
            [OperationTypeEnum.AssignUser]: bulkCreateUserToggle,
            [OperationTypeEnum.ChangeManager]: bulkEditManagerToggle,
          });
        },
      );
  }
}
